import React, { Component } from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import publicApi from './services/public';
import marketApi from './services/market';
import {ToastContainer, toast} from 'react-toastify';
import Preloader from './components/page/preloader';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => 
  ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2,
    },
    content: {
      margin: 0
    },
    buttonIcon: {
      margin: theme.spacing.unit,
    },
  });

const parseQueryString = (queryString) => {
  var params = {}, queries, temp, i, l;
  queries = queryString.split("&");

  for ( i = 0, l = queries.length; i < l; i++ ) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
  }
  return params;
};

class App extends Component {
  state = {
    draft: false,
    token: '',
    order_id: '',
    order_guid: '',
    order_name: '',
    amount: '',
    apikey: '',
    payment: 'credit',
    loaded: false,
  }

  componentDidMount() {
    const query = parseQueryString(window.location.search.substring(1));
    const {token, order_id, amount, apikey} = query;

    marketApi(`orders/${order_id}`, {}, 'GET', {}, token)
      .then(json => {
        const result = json.data || {};
        const {draft, payment_type, guids} = result;
        const order_guid = draft ? '' : guids[0].guid;

        this.setState({
          token,
          order_id,
          order_name: order_guid,
          order_guid,
          amount,
          apikey,
          loaded: true,
          draft,
          payment: payment_type,
        });
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: true});
      });
    
    
  }

  handleClose = () => {
    const {draft} = this.state;

    if (!draft) {
      window.parent.postMessage(JSON.stringify({name: 'cancel'}), "*");
    } else {
      window.parent.postMessage(JSON.stringify({name: 'close'}), "*");
    }
    
  };

  handleCredit = (order_guid) => {
    console.log(order_guid)
    const {order_name, amount} = this.state;
    let self = this;
    
    const widget = new window.cp.CloudPayments();

    widget.charge(
      {
        // publicId: 'pk_dd06144cfe657775a996ef82b37d9',
        publicId: 'pk_20e66caa3e9bb1c009cd85c27370e',
        description: 'Оплата',
        amount: parseFloat(amount),
        currency: 'RUB',
        invoiceId: order_guid,
      },
      (options) => {self.handleComplete(order_guid)},
      self.failPay
    );
  }

  // successPay = (order_guid) => {
  //   const {apikey} = this.state;
  //   publicApi('orders/transactions', {orderId: order_guid, transactionId: "00000000"}, "POST", {Apikey: apikey})
  //     .then(json => {
  //       debugger
  //     });
  //   this.handleComplete(order_guid);
  // }

  failPay = (options, fail) => {
    toast.info(options);
  }

  handleSubmit = () => {
    const {payment, order_id, token, draft, order_guid} = this.state;

    if (!draft) {
      if (payment === 'credit') {
        this.handleCredit(order_guid);
      } else {
        this.handleComplete(order_guid);
      }

      return
    }

    let agree = window.confirm('После подтверждения заказа Вы не сможете поменять состав заказа и изменить условия заказа. Вы подтверждаете заказ?');

    if (!agree) {
      return
    }

    this.setState({loaded: false});
    marketApi(`orders/${order_id}`, {draft: false, payment_type: payment}, 'PUT', {}, token)
      .then(json => {
        const result = json.data || {};
        const {draft, guids} = result;
        const order_guid = draft ? '' : guids[0].guid;

        if (payment === 'credit') {
          this.handleCredit(order_guid);
        } else {
          this.handleComplete(order_guid);
        }

        this.setState({
          order_guid,
          draft,
          loaded: true,
        });
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: true});
      });

    
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value})
  }

  handleComplete = (order_guid) => {
    window.parent.postMessage(JSON.stringify({params: {order_guid}, name: 'done'}), "*");
  }

  render() {
    const {classes, fullScreen} = this.props;
    const {draft, payment, loaded} = this.state;

    return (
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth = {'sm'}
        style={{margin: '0 !important'}}
        className={classes.content}
        open={true}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        keepMounted
      >
        <Grid container>
          <Grid item lg={8} md={8} xs={9} alignItems='center' container>
            <DialogTitle id="form-dialog-title">Оплата</DialogTitle>
          </Grid>
          <Grid item lg={4} md={4} xs={3} justify='flex-end' container>
            <IconButton onClick={this.handleClose} className={classes.buttonIcon} aria-label="Close">
              <ClearIcon fontSize="large"/>
            </IconButton>
          </Grid>
        </Grid>
        
        <Divider/>
        <ToastContainer/>
        <Preloader loaded={loaded}>
          <DialogContent>
            <form className={classes.root} autoComplete="off">
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="payment-helper">Способ оплаты</InputLabel>
                <Select
                  disabled={!draft}
                  autoWidth
                  value={payment}
                  onChange={this.handleChange}
                  input={<Input name="payment" id="payment-helper" />}
                >
                  <MenuItem value={'credit'}>Картой</MenuItem>
                  <MenuItem value={'cash'}>Наличными</MenuItem>
                </Select>
              </FormControl>
            </form>
            
          </DialogContent>
          <Divider/>
          <DialogActions style={{margin: 20}}>
            <Grid container>
              <Grid justify='flex-end' item md={12} sm={12} xs={12} lg={12} container>
                <Button variant='contained' color='primary' onClick={this.handleSubmit} className={classes.button}>
                  Оплатить
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Preloader>
        
      </Dialog>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({breakpoint: 'xs'})(withStyles(styles)(App));
